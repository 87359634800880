import React, { useEffect, useState, useRef } from "react";
import ActionCreator from "../actions/EventAction";
import {
  createScope,
  button,
  icon,
  searchBox,
  select,
  option,
  messageBar,
  text,
  dialog,
} from "@harmony/enablers/react";
import { AgGridReact } from "ag-grid-react";
import Moment from "moment";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  fetchAdmins,
  SetDialogIsOpen,
  SetInEditMode,
  SetCredentailRowData,
  saveBulkCalendarAdmins,
  SetCalendarAdminSpinner,
  SetIsOpenSuccessMsgBar,
  SetIsOpenErrorMsgBar,
  SetIsOpenErrorsDialog,
  SetBulkValidationErrors,
  SetIsOpenCopyDialog,
  FetchReportingYears,
} from "../actions/calendarAdminAction";
import { AuthGuard } from "../Authentication/AuthGuard";
import { AppRoles, AuthGuardType } from "../constants";
import { CredentialsApi } from "../adapters/credetialsIApi";
import { CalendarAdminsApi } from "../adapters/calendarAdminsIApi";
import { Spinner } from "../components/Spinner";
import AddEditCalAdminDialog from "../components/CalendarAdminDialog";
import { SetLoggedInUserEmail } from "../actions/FetchMasterData";
import { useMsal } from "@azure/msal-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import "../styles/ecstCommon.css";
import { MasterData } from "../models/MasterData";
import { getDefaultCalendarAdminFormData } from "../models/CalendarAdminFormData";
import {
  useCheckIfAuthorized,
  useGetLoggedInUserEmail,
} from "../Authentication/AuthUtil";
import CopyDialog from "../components/CopyDialog";
import { getAppInsights } from "../Telemetry/TelemetryService";
const scope = createScope({
  reactInstance: React,
});

const Button = scope.forReact(button);
const Icon = scope.forReact(icon);
const SearchBox = scope.forReact(searchBox);
const Select = scope.forReact(select);
const Option = scope.forReact(option);
const MessageBar = scope.forReact(messageBar);
const Text = scope.forReact(text);
const Dialog = scope.forReact(dialog);

const Admins = () => {
  const {
    dialogIsOpen,
    validationErrors,
    isSpinner,
    isMsgBar,
    isOpenErrorDialog,
    notificationMsg,
    isErrorMsgBar,
    errorNotificationMsg,
    isOpenCopyDialog,
  } = useSelector((state) => state.dataCalendarAdmin);
  const { instance } = useMsal();
  useEffect(() => {
    getMasterData();
    const currentAccount = instance.getActiveAccount();
    if (currentAccount) {
      dispatch(SetLoggedInUserEmail(currentAccount.username));
    }
    dispatch(fetchAdmins());
    dispatch(SetInEditMode(false));
    dispatch(SetDialogIsOpen(false));
    //  dispatch(SetCalendarAdminSpinner(true));
  }, []);

  const { admins } = useSelector((state) => state.dataCalendarAdmin);
  let dispatch = useDispatch();

  const credentialsApi = new CredentialsApi();
  const calendarAdminsApi = new CalendarAdminsApi();
  const [calendarAdminRowData, setCalendarAdminRowData] = useState(
    getDefaultCalendarAdminFormData()
  );
  const [isOpen, setIsOpen] = useState(false);
  const [inEditMode, setinEditMode] = useState(false);
  const [pageSize, SetPageSize] = useState("20");
  const [RegionList, SetRegionList] = useState([]);
  const [CountryList, SetCountryList] = useState([]);
  const [ProvinceList, SetProvinceList] = useState([]);
  const [ReportingStatusList, SetReportingStatusList] = useState([]);
  const [AuditStatusList, SetAuditStatusList] = useState([]);
  const [masterDataList, SetMasterData] = useState(new MasterData());
  const [showSpinner, SetShowSpinner] = useState(false);
  const [isOpenSuccessMsgBar, setIsOpenSuccessMsgBar] = useState(false);
  const [isOpenErrorMsgBar, setIsOpenErrorMsgBar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState();
  const [ErrorNotificationMessage, SetErrorNotificationMessage] = useState();
  const { loggedInUserEmail } = useGetLoggedInUserEmail();
  const { appInsights } = getAppInsights();
  const gridRef = useRef();
  const columns = [
    {
      headerName: "Event Name",
      field: "EventName",
      tooltipField: "EventName",
    },
    {
      headerName: "Reporting Year",
      field: "ReportingPeriod",
      tooltipField: "ReportingPeriod",
    },
    {
      headerName: "Region",
      field: "Region",
      tooltipField: "Region",
    },
    {
      headerName: "Country",
      field: "Country",
      tooltipField: "Country",
    },
    {
      headerName: "Province",
      field: "Province",
      tooltipField: "Province",
    },
    {
      headerName: "Company Code",
      field: "CompanyCode",
      tooltipField: "CompanyCode",
    },
    {
      headerName: "Scheme",
      field: "Scheme",
      tooltipField: "Scheme",
    },

    {
      headerName: "Compliance Org Name",
      field: "OrgName",
      tooltipField: "OrgName",
    },
    {
      headerName: "Reporting Frequency",
      field: "ReportingFrequency",
      tooltipField: "ReportingFrequency",
    },
    {
      headerName: "Reporting Deadline",
      field: "ReportingDeadline",
      tooltipField: "ReportingDeadline",
    },
    {
      headerName: "Reporting LOB",
      field: "ReportingLOB",
      tooltipField: "Region",
    },
    {
      headerName: "Microsoft ETA Completion",
      field: "MicrosoftETACompletion",
      tooltipField: "MicrosoftETACompletion",
    },
    {
      headerName: "Microsoft Final Deadline",
      field: "MicrosoftFinalDeadline",
      tooltipField: "MicrosoftFinalDeadline",
    },
    {
      headerName: "Reporting Status",
      field: "ReportingStatus",
      tooltipField: "ReportingStatus",
    },
    {
      headerName: "Location",
      field: "Location",
      tooltipField: "Location",
    },
    {
      headerName: "Audit Schedule",
      field: "AuditSchedule",
      tooltipField: "AuditSchedule",
    },
    {
      headerName: "Audit Status",
      field: "AuditStatus",
      tooltipField: "AuditStatus",
    },
    {
      headerName: "Notes",
      field: "Notes",
      tooltipField: "Notes",
    },
    {
      headerName: "Updated By",
      field: "UpdatedBy",
      tooltipField: "UpdatedBy",
    },
    {
      headerName: "Updated On",
      field: "UpdatedOn",
      tooltipField: "UpdatedOn",
    },
    {
      headerName: "Edit",
      field: "id",
      cellRendererFramework: (params) => (
        <div>
          <Button
            onClick={() => editRowHandler(params.data)}
            title="Edit"
            appearance="command"
          >
            <Icon slot="start" name="edit"></Icon>
          </Button>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    headerClass: "header-style",
    cellStyle: { textAlign: "center" },
    suppressSizeToFit: true,
    suppressKeyboardEvent: (params) => {
      const e = params.event;
      if (e.code == "Tab" || e.key == "Tab") {
        //get focusable children of parent cell
        let focusableChildrenOfParent = e.srcElement
          .closest(".ag-cell")
          .querySelectorAll(
            'he-button, [href], :not(.ag-hidden) > input, select, textarea, [tabindex]:not([tabindex="-1"])'
          );
        const { rowIndex, column } = params.api.getFocusedCell();

        if (
          rowIndex === pageSize - 1 &&
          column.getColId() ===
            params.api.getColumnDefs()[params.api.getColumnDefs().length - 1]
              .field &&
          e.srcElement
            .closest(".ag-cell")
            .querySelectorAll('he-button[title="Edit"]').length > 0 &&
          e.shiftKey == false
        ) {
          // last row and column
          return true;
        } else if (
          focusableChildrenOfParent.length == 0 ||
          (e.shiftKey == false &&
            e.srcElement ==
              focusableChildrenOfParent[
                focusableChildrenOfParent.length - 1
              ]) ||
          (e.shiftKey == true &&
            e.srcElement == focusableChildrenOfParent[0]) ||
          (e.shiftKey == true && e.srcElement.classList.contains("ag-cell"))
        ) {
          return false; //do not suppress
        }

        return true; //suppress
      }
      return false; //do not suppress by default
    },
  };
  const state = {
    weekendsVisible: true,
    currentEvents: [],
  };
  const onGridReady = (params) => {
    //setGridApi(params.api)
  };

  const getMasterData = async () => {
    const res = await credentialsApi.FetchMasterData();
    SetMasterData(res.data);
    SetRegionList(res.data.Regions);
    SetReportingStatusList(res.data.ReportingStatuses);
    SetAuditStatusList(res.data.AuditStatuses);
  };

  const onExportClick = () => {
    var csvParams = {
      columnKeys: [
        "EventName",
        "ReportingPeriod",
        "Region",
        "Country",
        "Province",
        "CompanyCode",
        "Scheme",
        "OrgName",
        "ReportingFrequency",
        "ReportingDeadline",
        "ReportingLOB",
        "MicrosoftETACompletion",
        "MicrosoftFinalDeadline",
        "ReportingStatus",
        "Location",
        "AuditSchedule",
        "AuditStatus",
        "Notes",
      ],
      allColumns: false,
      fileName: "ECSTAdminList_" + Moment().format("DDMMYY") + ".csv",
      skipHeader: false,
    };
    gridRef.current.api.exportDataAsCsv(csvParams);
  };

  const onFilterTextChange = (e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  };

  const onPaginationChange = (pageSize) => {
    gridRef.current.api.paginationSetPageSize(pageSize);
    SetPageSize(pageSize);
  };

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const onDownloadClick = async () => {
    dispatch(SetCalendarAdminSpinner(true));
    try {
      const response = await credentialsApi.DownloadCalendarAdminTemplate();
      const url = window.URL.createObjectURL(new Blob([response.data]), {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ECSTCalendarAdmin-Template.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (ex) {
      appInsights.trackException({ exception: ex });
      SetErrorNotificationMessage(
        "Your request is not processed. Could you please try again later."
      );
      setIsOpenErrorMsgBar(true);
    } finally {
      dispatch(SetCalendarAdminSpinner(false));
    }
  };

  const AddRow = async () => {
    handleCloseDialog();
    SetShowSpinner(true);

    try {
      const formData = new FormData();
      var regionId = RegionList.filter(
        (x) => x.RegionName == calendarAdminRowData.Region
      )[0].RegionId;
      var countryId = CountryList.filter(
        (x) => x.CountryName == calendarAdminRowData.Country
      )[0].CountryId;
      var provinceId =
        ProvinceList.filter(
          (x) => x.ProvinceName == calendarAdminRowData.Province
        ).length > 0
          ? ProvinceList.filter(
              (x) => x.ProvinceName == calendarAdminRowData.Province
            )[0].ProvinceId
          : "0";
      var reportingStatusId = ReportingStatusList.filter(
        (x) => x.Status == calendarAdminRowData.ReportingStatus
      )[0].ReportingStatusId;
      var auditStatusId =
        AuditStatusList.filter(
          (x) => x.Status == calendarAdminRowData.AuditStatus
        ).length > 0
          ? AuditStatusList.filter(
              (x) => x.Status == calendarAdminRowData.AuditStatus
            )[0].AuditStatusId
          : null;

      formData.append("EventName", calendarAdminRowData.EventName);
      formData.append("ReportingPeriod", calendarAdminRowData.ReportingPeriod);
      formData.append("RegionId", regionId);
      formData.append("CountryId", countryId);
      formData.append("ProvinceId", provinceId);
      formData.append("CompanyCode", calendarAdminRowData.CompanyCode);
      formData.append("Scheme", calendarAdminRowData.Scheme);
      formData.append("OrgName", calendarAdminRowData.OrgName);
      formData.append(
        "ReportingFrequency",
        calendarAdminRowData.ReportingFrequency
      );
      formData.append(
        "ReportingDeadline",
        calendarAdminRowData.ReportingDeadline
      );
      formData.append("ReportingLOB", calendarAdminRowData.ReportingLOB);
      formData.append(
        "MicrosoftETACompletion",
        calendarAdminRowData.MicrosoftETACompletion
      );
      formData.append(
        "MicrosoftFinalDeadline",
        calendarAdminRowData.MicrosoftFinalDeadline
      );
      formData.append("ReportingStatusId", reportingStatusId);
      formData.append("Location", calendarAdminRowData.Location);
      formData.append(
        "AuditSchedule",
        calendarAdminRowData.AuditSchedule == null
          ? ""
          : calendarAdminRowData.AuditSchedule
      );
      formData.append(
        "AuditStatusId",
        auditStatusId == null ? "" : auditStatusId
      );
      formData.append("Notes", calendarAdminRowData.Notes);
      formData.append("CreatedBy", await loggedInUserEmail());
      formData.append("UpdatedBy", await loggedInUserEmail());

      const response = await calendarAdminsApi.PostSingleCalendarAdminData(
        formData
      );

      if (response.status === 201) {
        dispatch(fetchAdmins());
        setNotificationMessage("Added row successfully!");
        setIsOpenSuccessMsgBar(true);
        dispatch(ActionCreator.requestEvents());
      } else {
        SetErrorNotificationMessage(
          "Your request is not processed. Could you please try again later."
        );
        setIsOpenErrorMsgBar(true);
      }
    } catch (err) {
      console.log(err, "=========");
      if (err.response.request.status === 409) {
        appInsights.trackException({ exception: err });
        SetErrorNotificationMessage(
          "Events with following combinations " +
            "Event Name: " +
            calendarAdminRowData.EventName +
            "," +
            "Reporting Year: " +
            calendarAdminRowData.ReportingPeriod +
            "," +
            "Region: " +
            calendarAdminRowData.Region +
            "," +
            "Country: " +
            calendarAdminRowData.Country +
            "," +
            "Province: " +
            calendarAdminRowData.Province +
            "," +
            "Scheme: " +
            calendarAdminRowData.Scheme +
            "," +
            "ReportingLOB: " +
            calendarAdminRowData.ReportingLOB +
            "," +
            "CompanyCode: " +
            calendarAdminRowData.CompanyCode +
            "," +
            "ReportingFrequency: " +
            calendarAdminRowData.ReportingFrequency +
            " already exists!"
        );
        setIsOpenErrorMsgBar(true);
      } else {
        SetErrorNotificationMessage(
          "Your request is not processed. Could you please try again later."
        );
        setIsOpenErrorMsgBar(true);
      }
    } finally {
      SetShowSpinner(false);
    }
  };
  const UpdateRow = async () => {
    handleCloseDialog();
    SetShowSpinner(true);
    try {
      const formData = new FormData();
      var regionId = RegionList.filter(
        (x) => x.RegionName == calendarAdminRowData.Region
      )[0].RegionId;
      var countryId = CountryList.filter(
        (x) => x.CountryName == calendarAdminRowData.Country
      )[0].CountryId;
      var provinceId =
        ProvinceList.filter(
          (x) => x.ProvinceName == calendarAdminRowData.Province
        ).length > 0
          ? ProvinceList.filter(
              (x) => x.ProvinceName == calendarAdminRowData.Province
            )[0].ProvinceId
          : "0";
      var reportingStatusId = ReportingStatusList.filter(
        (x) => x.Status == calendarAdminRowData.ReportingStatus
      )[0].ReportingStatusId;
      var auditStatusId =
        AuditStatusList.filter(
          (x) => x.Status == calendarAdminRowData.AuditStatus
        ).length > 0
          ? AuditStatusList.filter(
              (x) => x.Status == calendarAdminRowData.AuditStatus
            )[0].AuditStatusId
          : null;
      formData.append("Id", calendarAdminRowData.Id);
      formData.append("EventName", calendarAdminRowData.EventName);
      formData.append("ReportingPeriod", calendarAdminRowData.ReportingPeriod);
      formData.append("Region", regionId);
      formData.append("Country", countryId);
      formData.append("Province", provinceId);
      formData.append("CompanyCode", calendarAdminRowData.CompanyCode);
      formData.append("Scheme", calendarAdminRowData.Scheme);
      formData.append("OrgName", calendarAdminRowData.OrgName);
      formData.append(
        "ReportingFrequency",
        calendarAdminRowData.ReportingFrequency
      );
      formData.append(
        "ReportingDeadline",
        calendarAdminRowData.ReportingDeadline
      );
      formData.append("ReportingLOB", calendarAdminRowData.ReportingLOB);
      formData.append(
        "MicrosoftETACompletion",
        calendarAdminRowData.MicrosoftETACompletion
      );
      formData.append(
        "MicrosoftFinalDeadline",
        calendarAdminRowData.MicrosoftFinalDeadline
      );
      formData.append("ReportingStatus", reportingStatusId);
      formData.append("Location", calendarAdminRowData.Location);
      formData.append(
        "AuditSchedule",
        calendarAdminRowData.AuditSchedule == null
          ? ""
          : calendarAdminRowData.AuditSchedule
      );
      formData.append(
        "AuditStatusId",
        auditStatusId == null ? "" : auditStatusId
      );
      formData.append("Notes", calendarAdminRowData.Notes);
      formData.append("UpdatedBy", await loggedInUserEmail());
      const response = await calendarAdminsApi.UpdateCalendarAdminData(
        formData
      );

      if (response.status === 201) {
        dispatch(fetchAdmins());
        setNotificationMessage("Updated row successfully!");
        setIsOpenSuccessMsgBar(true);
        dispatch(ActionCreator.requestEvents());
      } else {
        SetErrorNotificationMessage(
          "Your request is not processed. Could you please try again later."
        );
        setIsOpenErrorMsgBar(true);
      }
    } catch (err) {
      if (err.response.request.status === 409) {
        appInsights.trackException({ exception: err });
        SetErrorNotificationMessage(
          "Events with following combinations " +
            "Event Name: " +
            calendarAdminRowData.EventName +
            "," +
            "Reporting Year: " +
            calendarAdminRowData.ReportingPeriod +
            "," +
            "Region: " +
            calendarAdminRowData.Region +
            "," +
            "Country: " +
            calendarAdminRowData.Country +
            "," +
            "Province: " +
            calendarAdminRowData.Province +
            "," +
            "Scheme: " +
            calendarAdminRowData.Scheme +
            "," +
            "ReportingLOB: " +
            calendarAdminRowData.ReportingLOB +
            "," +
            "CompanyCode: " +
            calendarAdminRowData.CompanyCode +
            "," +
            "ReportingFrequency: " +
            calendarAdminRowData.ReportingFrequency +
            " already exists!"
        );
        setIsOpenErrorMsgBar(true);
      } else {
        SetErrorNotificationMessage(
          "Your request is not processed. Could you please try again later."
        );
        setIsOpenErrorMsgBar(true);
      }
    } finally {
      SetShowSpinner(false);
    }
  };

  const addSingleRowHandler = () => {
    setCalendarAdminRowData(getDefaultCalendarAdminFormData());
    setinEditMode(false);
    handleOpenDialog();
  };

  const editRowHandler = async (rowData) => {
    SetShowSpinner(true);
    try {
      let newRowData = rowData;
      newRowData.ReportingDeadline = convertDate(newRowData.ReportingDeadline);
      newRowData.MicrosoftETACompletion = convertDate(
        newRowData.MicrosoftETACompletion
      );
      newRowData.MicrosoftFinalDeadline = convertDate(
        newRowData.MicrosoftFinalDeadline
      );
      newRowData.AuditSchedule =
        newRowData.AuditSchedule != null && newRowData.AuditSchedule != ""
          ? convertDate(newRowData.AuditSchedule)
          : "";
      setCalendarAdminRowData(newRowData);
      let regionName = rowData.Region;
      let seletedRegionId = masterDataList.Regions.filter(
        (x) => x.RegionName == regionName
      )[0].RegionId;
      const countries = masterDataList.Countries.filter(
        (x) => x.RegionId == seletedRegionId
      );
      SetCountryList(countries);
      let CountryName = rowData.Country;
      var countryId = masterDataList.Countries.filter(
        (x) => x.CountryName == CountryName
      )[0].CountryId;
      const provinces = masterDataList.Provinces.filter(
        (x) => x.CountryId == countryId
      );
      SetProvinceList(provinces);
      setinEditMode(true);
      SetShowSpinner(false);
      handleOpenDialog();
      dispatch(fetchAdmins());
    } catch (err) {
      appInsights.trackException({ exception: err });
      SetShowSpinner(false);
    }
  };

  const copyEventsClickHandler = async () => {
    dispatch(await FetchReportingYears());
    dispatch(SetIsOpenCopyDialog(true));
  };
  const onFormControlChange = (e) => {
    const { name, value } = e.target;

    if (name == "Region") {
      if (typeof e.target.selectedOption !== "undefined") {
        var regionId = e.target.selectedOption.getAttribute("data-regionid");
        var countries = masterDataList.Countries.filter(
          (x) => x.RegionId == regionId
        );
        SetCountryList(countries);
      }
    }
    if (name == "Country") {
      if (masterDataList.Provinces.length > 0) {
        if (typeof e.target.selectedOption !== "undefined") {
          var countryId =
            e.target.selectedOption.getAttribute("data-countryid");
          var provinces = masterDataList.Provinces.filter(
            (x) => x.CountryId == countryId
          );
          SetProvinceList(provinces);
        }
      }
    }
    if (name == "AuditSchedule" && e.type == "he-clear") {
      setCalendarAdminRowData((calendarAdminRowData) => ({
        ...calendarAdminRowData,
        [name]: "",
      }));
      return;
    }
    setCalendarAdminRowData((calendarAdminRowData) => ({
      ...calendarAdminRowData,
      [name]: value,
    }));
  };

  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const convertDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const importExcel = async (e) => {
    dispatch(SetBulkValidationErrors([]));
    dispatch(SetCalendarAdminSpinner(true));
    dispatch(await saveBulkCalendarAdmins(e));
    hiddenFileInput.current.value = "";
  };

  const getSchemaErrors = (data) => {
    if (data != undefined || data != null) {
      return (
        <div>
          <p>
            Please correct the below following errors and reupload the template
            again
          </p>
          <table className="table">
            <thead>
              <tr>
                <th>Row Number</th>
                <th>Error Message</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => {
                return (
                  <tr>
                    <td>{row.Path}</td>
                    <td>{row.Message}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
  };

  return admins.loading ? (
    <h3>Loading Data</h3>
  ) : admins.error ? (
    <h3>{admins.error}</h3>
  ) : (
    <div style={{ borderTop: "1px solid #ddd", height: "100%" }}>
      <h1 tabIndex="-1">Admin</h1>
      <div id="navigation-announcer" role="alert" aria-live="polite">
        Navigated to Admin Page
      </div>
      <div className="buttonContainerStyle" style={{ paddingTop: "15px" }}>
        <Button
          onClick={() => onExportClick()}
          title="Export to CSV"
          appearance="command"
        >
          <Icon slot="start" name="excellogoinverse"></Icon>Export
        </Button>

        {isOpen == true ? (
          <AddEditCalAdminDialog
            data={calendarAdminRowData}
            onSave={AddRow}
            onEdit={UpdateRow}
            handleOpenDialog={handleOpenDialog}
            handleCloseDialog={handleCloseDialog}
            isOpen={isOpen}
            onFormControlChange={onFormControlChange}
            inEditMode={inEditMode}
            RegionList={RegionList}
            CountryList={CountryList}
            ProvinceList={ProvinceList}
            ReportingStatusList={ReportingStatusList}
            AuditStatusList={AuditStatusList}
            masterDataList={masterDataList}
            SetCountryList={SetCountryList}
            setCalendarAdminRowData={setCalendarAdminRowData}
          ></AddEditCalAdminDialog>
        ) : null}

        <AuthGuard
          roles={[AppRoles.ECSTAdmin]}
          typeOfGuard={AuthGuardType.Control}
        >
          <Button
            onClick={() => addSingleRowHandler()}
            appearance="command"
            title="Add single new row"
          >
            <Icon slot="start" name="add"></Icon>Add Single Row
          </Button>
          <Button appearance="command" onClick={handleClick}>
            Add Multiple Rows<Icon slot="start" name="upload"></Icon>
          </Button>
          <input
            type="file"
            ref={hiddenFileInput}
            style={{ display: "none" }}
            onInput={importExcel}
          />
          <Button
            title="Download template"
            onClick={() => onDownloadClick()}
            appearance="command"
          >
            <Icon slot="start" name="download"></Icon>Download Template
          </Button>
        </AuthGuard>
        <Text appearance="paragraph" style={{ paddingTop: "6px" }}>
          Page Size
        </Text>
        <Select
          value={pageSize}
          onHeChange={(e) => onPaginationChange(e.target.value)}
          style={{ width: "95px", paddingLeft: "10px" }}
          label="Page Size"
          hideLabel="true"
        >
          <Icon slot="start" name="filter" className="pageSizeStyle"></Icon>
          <Option value="5">5</Option>
          <Option value="10">10</Option>
          <Option value="15">15</Option>
          <Option value="20">20</Option>
        </Select>
        <SearchBox
          style={{ width: "20%", paddingLeft: 0, marginRight: 0 }}
          onHeChange={onFilterTextChange}
          className="container"
        />
      </div>
      <Button
        onClick={() => copyEventsClickHandler()}
        appearance="command"
        title="Copy Events"
      >
        <Icon slot="start" name="copy"></Icon>Copy Multiple Events
      </Button>
      <MessageBar
        appearance="success"
        open={isMsgBar}
        onHeAfterHide={() => dispatch(SetIsOpenSuccessMsgBar(false))}
        style={{ marginBottom: "1rem" }}
        duration="5000"
      >
        <div role="alert" aria-live="assertive">
          {notificationMsg}
        </div>
      </MessageBar>
      <MessageBar
        appearance="error"
        open={isErrorMsgBar}
        onHeAfterHide={() => dispatch(SetIsOpenErrorMsgBar(false))}
        duration="10000"
      >
        <div role="alert" aria-live="assertive">
         
          {errorNotificationMsg}
        </div>
      </MessageBar>
      <MessageBar
        appearance="success"
        open={isOpenSuccessMsgBar}
        onHeAfterHide={() => setIsOpenSuccessMsgBar(false)}
        style={{ marginBottom: "1rem" }}
        duration="5000"
      >
        <div role="alert" aria-live="assertive">
          {notificationMessage}
        </div>
      </MessageBar>
      <MessageBar
        appearance="error"
        open={isOpenErrorMsgBar}
        onHeAfterHide={() => setIsOpenErrorMsgBar(false)}
        duration="30000"
      >
        <div role="alert" aria-live="assertive">
      
          {ErrorNotificationMessage}
        </div>
      </MessageBar>
      <Dialog
        heading="Validation Errors!!"
        style={{ width: "500px" }}
        open={isOpenErrorDialog}
        onHeAfterHide={() => dispatch(SetIsOpenErrorsDialog(false))}
      >
        {getSchemaErrors(validationErrors)}

        <Button
          slot="footer"
          appearance="primary"
          onClick={() => dispatch(SetIsOpenErrorsDialog(false))}
        >
          Ok
        </Button>
      </Dialog>
      <Spinner ShowSpinner={showSpinner}></Spinner>
      <Spinner ShowSpinner={isSpinner}></Spinner>
      <div
        className="ag-theme-alpine"
        style={{
          width: "100%",
          paddingTop: "0px",
          paddingRight: "0px",
          borderRadius: 90,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={admins}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          enableBrowserTooltips={true}
          onGridReady={onGridReady}
          pagination={true}
          paginationPageSize={20}
          domLayout="autoHeight"
          ensureDomOrder={true}
        />

        {isOpenCopyDialog ? <CopyDialog /> : null}
      </div>
    </div>
  );
};

export default Admins;
